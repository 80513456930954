var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"data-list"}},[_c('vx-card',{ref:"filterCard",staticClass:"user-list-filters mb-8",attrs:{"title":"Add Time","collapse-action":"","refresh-content-action":""},on:{"refresh":_vm.resetColFilters}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return _c('form',{ref:"form",attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full mb-2"},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col md:w-1/2 sm:w-1/2 w-full mb-2"},[_c('label',{staticClass:"text-sm opacity-75"},[_c('span',{staticClass:"text-primary"},[_vm._v("*")]),_vm._v("FROM ")]),_c('validation-provider',{attrs:{"name":"from","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"icon-pack":"feather","icon":"icon-chevron-right","icon-no-border":"","type":"time","danger":!!errors[0],"danger-text":errors[0]},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}})]}}],null,true)})],1),_c('div',{staticClass:"vx-col md:w-1/2 sm:w-1/2 w-full mb-2"},[_c('label',{staticClass:"text-sm opacity-75"},[_c('span',{staticClass:"text-primary"},[_vm._v("*")]),_vm._v("TO ")]),_c('validation-provider',{attrs:{"name":"to","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"icon-pack":"feather","icon":"icon-chevron-right","icon-no-border":"","type":"time","danger":!!errors[0],"danger-text":errors[0]},model:{value:(_vm.to),callback:function ($$v) {_vm.to=$$v},expression:"to"}})]}}],null,true)})],1),_c('div',{staticClass:"vx-col md:w-1/2 sm:w-1/2 w-full mb-2"},[_c('label',{staticClass:"text-sm opacity-75"},[_c('span',{staticClass:"text-primary"},[_vm._v("*")]),_vm._v("City ")]),_c('validation-provider',{attrs:{"name":"city_id","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mb-1",attrs:{"reduce":function (name) { return name.id; },"name":"city_id","label":"name","options":_vm.cities,"clearable":false,"dir":_vm.$vs.rtl ? 'rtl' : 'ltr',"multiple":"","required":!!errors[0],"placeholder":"City"},model:{value:(_vm.location.city_id),callback:function ($$v) {_vm.$set(_vm.location, "city_id", $$v)},expression:"location.city_id"}})]}}],null,true)})],1),_c('div',{staticClass:"vx-col md:w-1/2 sm:w-1/2 w-full mb-2"},[_c('label',{staticClass:"text-sm opacity-75"},[_c('span',{staticClass:"text-primary"},[_vm._v("*")]),_vm._v("Areas ")]),_c('validation-provider',{attrs:{"name":"area_id","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mb-1",attrs:{"reduce":function (name) { return name.id; },"label":"name","name":"area_id","options":_vm.areas,"clearable":false,"dir":_vm.$vs.rtl ? 'rtl' : 'ltr',"multiple":"","required":!!errors[0],"placeholder":"Area"},model:{value:(_vm.location.assigned_areas),callback:function ($$v) {_vm.$set(_vm.location, "assigned_areas", $$v)},expression:"location.assigned_areas"}})]}}],null,true)})],1),_c('vs-col',{staticClass:"md:w-1/2 sm:w-1/2 w-full mb-2",attrs:{"type":"flex","vs-justify":"left","vs-align":"left","vs-w":"6"}},[_c('div',{staticClass:"vx-card__title"},[_c('div',{staticClass:"cardx"},[_c('label',{staticClass:"text-sm opacity-75"},[_c('span',{staticClass:"text-primary"},[_vm._v("*")]),_vm._v("DAYS ")]),_c('vs-card',[_c('vs-row',[_c('validation-provider',{attrs:{"name":"areas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _vm._l((_vm.allDays),function(day,index){return _c('vs-col',{key:day + index,attrs:{"type":"flex","vs-justify":"left","vs-align":"left","vs-w":"6"}},[_c('vs-checkbox',{staticClass:"py-2",attrs:{"vs-value":day.name,"danger":!!errors[0],"danger-text":errors[0]},model:{value:(_vm.days),callback:function ($$v) {_vm.days=$$v},expression:"days"}},[_vm._v(_vm._s(day.name))])],1)})}}],null,true)})],1)],1)],1)])])],1)]),_c('div',{staticClass:"vx-col w-full mb-2"},_vm._l((_vm.assigned_companies),function(category,index){return _c('div',{key:index,staticClass:"vx-row"},[_c('div',{staticClass:"vx-col md:w-2/5 sm:w-2/5 w-full mb-2"},[_c('label',{staticClass:"text-sm opacity-75"},[_c('span',{staticClass:"text-primary"},[_vm._v("*")]),_vm._v("COMPANY ")]),_c('validation-provider',{attrs:{"vid":'vp' + category.id,"name":"assigned_companies","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mb-4",attrs:{"label":"name","name":"assigned_companies","reduce":function (name) { return name.id; },"options":_vm.companies,"clearable":false,"dir":_vm.$vs.rtl ? 'rtl' : 'ltr',"required":!!errors[0],"placeholder":"Company"},model:{value:(category.company_id),callback:function ($$v) {_vm.$set(category, "company_id", $$v)},expression:"category.company_id"}})]}}],null,true)})],1),_c('div',{staticClass:"vx-col md:w-2/5 sm:w-2/5 w-full mb-2"},[_c('label',{staticClass:"text-sm opacity-75"},[_c('span',{staticClass:"text-primary"},[_vm._v("*")]),_vm._v("CATEGORY ")]),_c('validation-provider',{attrs:{"vid":'vp' + index,"name":'categories' + index,"rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mb-4 md:mb-0 vs_error",attrs:{"label":"name","reduce":function (name) { return name.id; },"multiple":"","name":'categories' + index,"options":_vm.categories,"clearable":false,"dir":_vm.$vs.rtl ? 'rtl' : 'ltr',"required":!!errors[0],"placeholder":"Category"},model:{value:(category.categories),callback:function ($$v) {_vm.$set(category, "categories", $$v)},expression:"category.categories"}})]}}],null,true)})],1),_c('div',{staticClass:"vx-col md:w-1/5 sm:w-1/5 w-full m-auto",staticStyle:{"display":"flex","align-items":"flex-end","justify-content":"flex-end"}},[_c('vs-button',{staticClass:"mr-2",staticStyle:{"floate":"inline-start !important"},attrs:{"radius":"","color":"success","type":"border","icon":"add"},on:{"click":function($event){return _vm.addCompany()}}}),(index != 0)?_c('vs-button',{staticStyle:{"floate":"inline-start !important"},attrs:{"radius":"","color":"danger","type":"border","icon":"delete"},on:{"click":function($event){return _vm.removeCompany(index)}}}):_vm._e()],1)])}),0)]),_c('vs-button',{staticClass:"mb-2",attrs:{"type":"filled"},on:{"click":function($event){validate().then(_vm.addTime)}}},[_vm._v("Send")])],1)}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }