<!--*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===
  - Project: HR APPLICATION
  - Author: Salwa Abuwarda*
  - Author URL: http://linkedin.com/in/salwa-abuwarda-20b06a142
  ===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===* -->

<template>
  <div id="data-list">
    <vx-card
      ref="filterCard"
      title="Add Time"
      class="user-list-filters mb-8"
      collapse-action
      refresh-content-action
      @refresh="resetColFilters"
    >
      <validation-observer ref="observer">
        <form ref="form" slot-scope="{ validate }" autocomplete="off">
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <div class="vx-row">
                <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
                  <label class="text-sm opacity-75">
                    <span class="text-primary">*</span>FROM
                  </label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="from"
                    rules="required|min:4"
                  >
                    <vs-input
                      class="w-full"
                      icon-pack="feather"
                      icon="icon-chevron-right"
                      icon-no-border
                      type="time"
                      v-model="from"
                      :danger="!!errors[0]"
                      :danger-text="errors[0]"
                    />
                  </validation-provider>
                </div>
                <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
                  <label class="text-sm opacity-75">
                    <span class="text-primary">*</span>TO
                  </label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="to"
                    rules="required|min:4"
                  >
                    <vs-input
                      class="w-full"
                      icon-pack="feather"
                      icon="icon-chevron-right"
                      icon-no-border
                      type="time"
                      v-model="to"
                      :danger="!!errors[0]"
                      :danger-text="errors[0]"
                    />
                  </validation-provider>
                </div>
                <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
                  <label class="text-sm opacity-75">
                    <span class="text-primary">*</span>City
                  </label>
                  <validation-provider
                    name="city_id"
                    rules="required|min:1"
                    v-slot="{ errors }"
                  >
                    <v-select
                      :reduce="(name) => name.id"
                      name="city_id"
                      label="name"
                      :options="cities"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="location.city_id"
                      class="mb-1"
                      multiple
                      :required="!!errors[0]"
                      placeholder="City"
                    />
                  </validation-provider>
                </div>
                <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
                  <label class="text-sm opacity-75">
                    <span class="text-primary">*</span>Areas
                  </label>
                  <validation-provider
                    name="area_id"
                    rules="required|min:1"
                    v-slot="{ errors }"
                  >
                    <v-select
                      :reduce="(name) => name.id"
                      label="name"
                      name="area_id"
                      :options="areas"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="location.assigned_areas"
                      class="mb-1"
                      multiple
                      :required="!!errors[0]"
                      placeholder="Area"
                    />
                  </validation-provider>
                </div>

                <vs-col
                  class="md:w-1/2 sm:w-1/2 w-full mb-2"
                  type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="6"
                >
                  <div class="vx-card__title">
                    <div class="cardx">
                      <label class="text-sm opacity-75">
                        <span class="text-primary">*</span>DAYS
                      </label>
                      <vs-card>
                        <vs-row>
                          <validation-provider
                            name="areas"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <vs-col
                              v-for="(day, index) in allDays"
                              :key="day + index"
                              type="flex"
                              vs-justify="left"
                              vs-align="left"
                              vs-w="6"
                            >
                              <vs-checkbox
                                class="py-2"
                                :vs-value="day.name"
                                v-model="days"
                                :danger="!!errors[0]"
                                :danger-text="errors[0]"
                                >{{ day.name }}</vs-checkbox
                              >
                            </vs-col>
                          </validation-provider>
                        </vs-row>
                      </vs-card>
                    </div>
                  </div>
                </vs-col>
              </div>
            </div>
            <div class="vx-col w-full mb-2">
              <div
                v-for="(category, index) of assigned_companies"
                :key="index"
                class="vx-row"
              >
                <div class="vx-col md:w-2/5 sm:w-2/5 w-full mb-2">
                  <label class="text-sm opacity-75">
                    <span class="text-primary">*</span>COMPANY
                  </label>
                  <validation-provider
                    v-slot="{ errors }"
                    :vid="'vp' + category.id"
                    name="assigned_companies"
                    rules="required|min:1"
                  >
                    <v-select
                      label="name"
                      name="assigned_companies"
                      :reduce="(name) => name.id"
                      :options="companies"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="category.company_id"
                      :required="!!errors[0]"
                      class="mb-4"
                      placeholder="Company"
                    />
                  </validation-provider>
                </div>
                <div class="vx-col md:w-2/5 sm:w-2/5 w-full mb-2">
                  <label class="text-sm opacity-75">
                    <span class="text-primary">*</span>CATEGORY
                  </label>
                  <validation-provider
                    v-slot="{ errors }"
                    :vid="'vp' + index"
                    :name="'categories' + index"
                    rules="required|min:1"
                  >
                    <v-select
                      label="name"
                      :reduce="(name) => name.id"
                      multiple
                      :name="'categories' + index"
                      :options="categories"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="category.categories"
                      :required="!!errors[0]"
                      class="mb-4 md:mb-0 vs_error"
                      placeholder="Category"
                    />
                  </validation-provider>
                </div>
                <div
                  class="vx-col md:w-1/5 sm:w-1/5 w-full m-auto"
                  style="
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                  "
                >
                  <vs-button
                    style="floate: inline-start !important"
                    @click="addCompany()"
                    radius
                    color="success"
                    class="mr-2"
                    type="border"
                    icon="add"
                  ></vs-button>
                  <vs-button
                    style="floate: inline-start !important"
                    v-if="index != 0"
                    @click="removeCompany(index)"
                    radius
                    color="danger"
                    type="border"
                    icon="delete"
                  ></vs-button>
                </div>
              </div>
            </div>
          </div>

          <vs-button
            type="filled"
            @click="validate().then(addTime)"
            class="mb-2"
            >Send</vs-button
          >
        </form>
      </validation-observer>
    </vx-card>
  </div>
</template>

<script>
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import vSelect from "vue-select";
import endpoints from "@/endpoints";
import moduleCitiesManagement from "@/store/get-all-cities/moduleCitiesManagement.js";
import moduleAreasManagement from "@/store/get-all-areas/moduleAreasManagement.js";

import axios from "@/axios.js";
// Store Module
// import moduleNewsManagement from "@/store/get-all-news/moduleNewsManagement.js";
import { required, min, numeric } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");
// betterEager
extend("required", {
  ...required,
  message: "field can not be empty",
});

extend("min", {
  ...min,
  message: "field may not be less than {length} characters",
});
extend("numeric", {
  ...numeric,
  message: "field should be a number",
});

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // Cell Renderer
  },
  watch: {
    "location.city_id"(obj) {
      this.areas = [];
      if (obj && obj.length) {
        this.$vs.loading();
        obj.forEach((element) => {
          this.$vs.loading.close();
          axios
            .get(`${endpoints.CITY_ENDPOINT}/${element}/areas`)
            .then((response) => {
              this.$vs.loading.close();
              let itemsAreas = response.data.response.data;
              if (itemsAreas.length) {
                itemsAreas.forEach((item) => {
                  let tempAreas = this.areas.map((i) => i.id);
                  if (!tempAreas.includes(item.id)) {
                    this.areas.push(item);
                  }
                });
              }

              // check select areas in all areas
              this.location.assigned_areas.forEach((item) => {
                let currentAreas = this.areas.map((i) => i.id);
                if (!currentAreas.includes(item)) {
                  const itemIndex = this.location.assigned_areas.findIndex((u) => u == item);
                  this.location.assigned_areas.splice(itemIndex, 1);
                }
              });
            });
        });
      } else {
        // clear select areas
        this.location.assigned_areas = [];
      }
    },
    to(obj) {
      if (obj.length === 5) {
        this.to = obj + ":00";
      }
    },
    from(obj) {
      if (obj.length === 5) {
        this.from = obj + ":00";
      }
    },
  },
  data() {
    return {
      location: {
        city_id: null,
        assigned_areas: [],
      },
      areas: [],
      from: null,
      to: null,
      days: [],
      allDays: [
        { id: 1, name: "Sunday" },
        { id: 2, name: "Monday" },
        { id: 3, name: "Tuesday" },
        { id: 4, name: "Wednesday" },
        { id: 5, name: "Thursday" },
        { id: 6, name: "Friday" },
        { id: 7, name: "Saturday" },
      ],
      show: false,
      assigned_areas: [],
      companies: [],
      assigned_companies: [{ categories: [], company_id: "" }],
      categories: [],
    };
  },
  computed: {
    itemsData() {
      return this.$store.state.time.items;
    },
    cities() {
      return this.$store.state.city.items;
    },
  },

  methods: {
    //    toggleDays(day) {
    //   if (this.days.indexOf(day) != -1) {
    //     let index = this.days.findIndex((e) => e === day);
    //     this.days.splice(index, 1);
    //   } else {
    //     this.days.push(day);
    //   }

    //   // console.log( this.permission);
    // },

    addCompany() {
      this.assigned_companies.push({
        campany_id: "",
        categories: "",
      });
    },
    removeCompany(i) {
      this.assigned_companies.splice(i, 1);
    },
    addTime() {
      // validate select (City , Areas ) or ( Company , categorys )
      if (this.location.assigned_areas.length == 0 && this.assigned_companies[0].categories.length == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please choose a (City , Areas ) or ( Company , categorys ), or both",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return
      }
      this.$vs.loading();
      const formData = new FormData();
      //time
      formData.append("from", this.from);
      formData.append("to", this.to);

      if (this.location.assigned_areas) {
        this.location.assigned_areas.forEach((element, i) => {
          formData.append("assigned_areas[" + i + "]", element);
        });
      }

      if (this.assigned_companies[0].company_id !== "") {
        // console.log(this.assigned_companies[0].company_id);
        this.assigned_companies.forEach((element, i) => {
          element.categories.forEach((item, index) => {
            formData.append(
              "assigned_companies[" + i + "][categories][" + index + "]",
              item
            );
          });

          formData.append(
            "assigned_companies[" + i + "][company_id]",
            element.company_id
          );
        });
      }
      this.days.forEach((element, i) => {
        formData.append("days[" + i + "]", element);
      });
      // alert(this.subject + ' , ' + this.body + ' , ' + this.category.name + ' , ' + this.company.id);
      axios
        .post(endpoints.TIME_ENDPOINT, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$vs.loading.close();
          // alert(JSON.stringify(response));
          if (response.status === 200) {
            this.$vs.notify({
              title: "Success",
              text: "Time is Added",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            this.$router.push("../GetAll/Time");
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Message is not sent",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: error.response.data.errors[0],
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
    getCities() {
      this.$vs.loading();
      this.$store.dispatch("city/fetchItemsCities").then((response) => {
        this.$vs.loading.close();
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      });
    },
    getCategories() {
      axios
        .get(`${process.env.VUE_APP_BASE_URI}/api/admin/categories`)
        .then((response) => {
          this.categories = response.data.response.data;
        });
    },
    getCompanies() {
      axios
        .get(`${process.env.VUE_APP_BASE_URI}/api/admin/companies`)
        .then((response) => {
          this.companies = response.data.response.data;
          this.companies.unshift({
              id: "",
              name: "Select company --"
            });
        });
    },
    resetTime() {
      this.from = "";
      this.to = "";
      this.days = [];
    },
    resetColFilters(card) {
      this.location.city_id = null;
      this.location.assigned_areas = [];
      this.from = "";
      this.to = "";
      this.days = [];
      this.areas = [];
      this.assigned_companies = [];
      this.assigned_companies.push({
        campany_id: "",
        categories: "",
      });
      card.removeRefreshAnimation(500);
    },
    goToTime() {
      this.$router.push("../GetAll/Time");
    },
  },
  mounted() {
    this.getCities();
    this.getCategories();
    this.getCompanies();
  },
  created() {
    if (!moduleCitiesManagement.isRegistered) {
      this.$store.registerModule("city", moduleCitiesManagement);
      moduleCitiesManagement.isRegistered = true;
    }
    if (!moduleAreasManagement.isRegistered) {
      this.$store.registerModule("areas", moduleAreasManagement);
      moduleAreasManagement.isRegistered = true;
    }
  },
};
</script>

<style lang="scss">
.err {
  border: #c00000 solid 1px;
}
#data-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
#file {
  padding: 6px !important;
}
.con-input-upload {
  padding: 0;
}
[dir] .con-img-upload {
  padding: 0;
  margin: 0;
}
[dir="rtl"] .ag-theme-material .ag-rtl {
  text-align: right;
}
[dir="rtl"] .ag-rtl {
  direction: rtl;
}
.cardx {
  width: calc(88% - 10px);
  margin: 5px;
}
.centerx li {
  margin: 8px 0;
}
.vx-card__title {
  margin: 5px;
}
</style>
